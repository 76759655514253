import React, { Component } from 'react';
import { authenticationService } from '../_services';


class Login extends Component {

    constructor(props) {
        super(props);
        this.state = { 
                premios: [{nome: 'Bilhete', valor: '10'},{nome: 'Garrafa Vinho', valor: '20'},{nome: 'Desconto SPA', valor: '30'}] ,
                username: '',
                password: '',
            }
        this.handleSubmit = this.handleSubmit.bind(this);
      }



      async handleSubmit(e) {
        e.preventDefault();
    
     
       //localStorage.setItem('api_url_grupoff', 'https://ecolux.boopgest.pt/api');

       localStorage.setItem('api_url_grupoff', 'https://localhost:44316');
        var message = await authenticationService.login(this.state.username, this.state.password)
    
        if (message.token == null)
          this.setState({ errorMessage: message })
    
      }



      handleChangeUserName = event => {
        this.setState({username: event.target.value});
    
        console.log('value is:', event.target.value);
      };

      handleChangePassword = event => {
        this.setState({password: event.target.value});
    
        console.log('value is:', event.target.value);
      };


    
    componentDidMount() {

        
      }

      render() {
    return (
      <>

<div class="bg-pattern-wrap ratio2_1">
    
      <div class="bg-patter">
        <img src="assets/images/banner/bg-login.png" class="bg-img" alt="pattern" />
      </div>
    </div>

 
    <main className="main-wrap login-page mb-xxl">
      <p style={{textAlign: 'center'}}><img className="logo" src="assets/images/logo/grupoff.png" alt="logo" /></p>
      <p className="font-sm content-color">Participa nos desafios que o <b>Grupo FF</b> preparou para si. Ao participares podes acumular pontos e ganhar prémios.</p>

    
      <section className="login-section p-0">
  
        <form  onSubmit={this.handleSubmit} class="custom-form">
          <h1 className="font-md title-color fw-600">Entrar</h1>

         
          <div className="input-box">
            <input type="text" placeholder="Username" required className="form-control" onChange={this.handleChangeUserName} value={this.state.username}/>
            <i data-feather="at-sign"></i>
          </div>
        
          <div className="input-box">
            <input type="password" placeholder="Password" required className="form-control" onChange={this.handleChangePassword} value={this.state.password} />
            <i className="iconly-Hide icli showHidePassword"></i>
          </div>
       
          <a href="forgot-password.html" className="content-color font-sm forgot mb-3">Esqueceu a Password ?</a>
          <button type="submit" className="btn-solid" >Entrar</button>
        </form>
    
      </section>
     
    </main>

  </>

);
    }
}

export default Login;
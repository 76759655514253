import React, { Component } from 'react';
import Header from '../paginas/header';
import Footer from '../paginas/footer';


class NOTFOUND extends Component {

    constructor(props) {
        super(props);
        this.state = { premios: [{nome: 'Bilhete', valor: '10'},{nome: 'Garrafa Vinho', valor: '20'},{nome: 'Desconto SPA', valor: '30yarn'}] }
    
      }

    componentDidMount() {

        
      }

      render() {
    return (
      <>
<Header />
<main class="main-wrap error-404 mb-xxl">

      <div class="banner-box">
        <img src="assets/images/banner/404.png" class="img-fluid" alt="404" />
      </div>

      <section class="error mb-large">
        <h2 class="font-lg">PAGE NOT FOUND</h2>
        <p class="content-color font-md">We are sorry but the page you are looking for doesn't exist or has been removed. Please check back later or search again.</p>
        <a href="index.html" class="btn-solid">Back to Home</a>
      </section>
 
    </main>
  <Footer />
  </>

);
    }
}

export default NOTFOUND;
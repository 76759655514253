


function Footer() {
  return (
    <>
   <footer className="footer-wrap">
    <ul className="footer">
      <li className="footer-item active">
        <a href="/home" className="footer-link">
          <i className="iconly-Home icli"></i>
          <span>Home</span>
        </a>
      </li>
      <li className="footer-item">
        <a href="category-wide.html" className="footer-link">
          <i className="iconly-Category icli"></i>
          <span>Category</span>
        </a>
      </li>
      <li className="footer-item">
        <a href="search.html" className="footer-link">
          <i className="iconly-Search icli"></i>
          <span>Search</span>
        </a>
      </li>
      <li className="footer-item">
        <a href="offer.html" className="footer-link">
         
          <span className="offer">Offers</span>
        </a>
      </li>
      <li className="footer-item">
        <a href="cart.html" className="footer-link">
          <i className="iconly-Bag-2 icli"></i>
          <span>Cart</span>
        </a>
      </li>
    </ul>
  </footer>

  </>

  );
}

export default Footer;
import React, { Component } from 'react';
import Header from '../paginas/header';
import Footer from '../paginas/footer';
import Protecao from '../paginas/protecao';


class Home extends Component {

    constructor(props) {
        super(props);
        this.state = { premios: [{nome: 'Bilhete', valor: '10'},{nome: 'Garrafa Vinho', valor: '20'},{nome: 'Desconto SPA', valor: '30'}] }
    
      }

    componentDidMount() {

        
      }

      render() {
    return (
      <>

<Header />


<main className="main-wrap index-page mb-xxl">
<div className="search-box">
    <i className="iconly-Search icli search"></i>
    <input className="form-control" type="search" placeholder="Procurar..." />
    <i className="iconly-Voice icli mic"></i>
  </div>


<section className="banner-section ratio2_1">
    <div className="h-banner-slider">
      <div>
        <div className="banner-box">
          <img src="assets/images/banner/home1.jpg" alt="banner" className="bg-img" />
          <div className="content-box">
            <h1 className="title-color font-md heading">Farm Fresh Veggies</h1>
            <p className="content-color font-sm">Get instant delivery</p>
            <a href="shop.html" className="btn-solid font-sm">Shop Now</a>
          </div>
        </div>
      </div>

      <div>
        
        <div className="banner-box">
          <img src="assets/images/banner/home2.jpg" alt="banner" className="bg-img" />
          <div className="content-box">
            <h2 className="font-white font-md heading">Farm Fresh Veggies</h2>
            <p className="font-white font-sm">Get instant delivery</p>
            <a href="shop.html" className="btn-outline font-sm">Shop Now</a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="category pt-0">
    <h3 className="font-md"><span>Indicadores Individuais </span><span className="line"></span></h3>
    <div className="row gy-sm-4 gy-2">
      <div className="col-3">
        <div className="category-wrap">
          <div className="bg-shape bg-theme-blue border-blue"></div>
          <a href="shop.html"> <img className="category img-fluid" src="assets/images/catagoeris/1.png" alt="category" /> </a>
          <span className="title-color">Oils,Refined & Ghee</span>
        </div>
      </div>

      <div className="col-3">
        <div className="category-wrap">
          <div className="bg-shape bg-theme-yellow border-yellow"></div>
          <a href="shop.html"> <img className="category img-fluid" src="assets/images/catagoeris/2.png" alt="category" /> </a>
          <span className="title-color">Rice, Flour & Grains</span>
        </div>
      </div>

      <div className="col-3">
        <div className="category-wrap">
          <div className="bg-shape bg-theme-orange border-orange"></div>
          <a href="shop.html"> <img className="category img-fluid" src="assets/images/catagoeris/3.png" alt="category" /> </a>
          <span className="title-color">Food Cupboard </span>
        </div>
      </div>

      <div className="col-3">
        <div className="category-wrap">
          <div className="bg-shape bg-theme-pink border-pink"></div>
          <a href="shop.html"> <img className="category img-fluid" src="assets/images/catagoeris/4.png" alt="category" /> </a>
          <span className="title-color">Fresh Fruits & Veggies </span>
        </div>
      </div>

      <div className="col-3">
        <div className="category-wrap">
          <div className="bg-shape bg-theme-purple border-purple"></div>
          <a href="shop.html"> <img className="category img-fluid" src="assets/images/catagoeris/5.png" alt="category" /> </a>
          <span className="title-color">Drinks& Beverages</span>
        </div>
      </div>

      <div className="col-3">
        <div className="category-wrap">
          <div className="bg-shape bg-theme-blue border-blue"></div>
          <a href="shop.html"> <img className="category img-fluid" src="assets/images/catagoeris/6.png" alt="category" /> </a>
          <span className="title-color">Instant Mixes </span>
        </div>
      </div>

      <div className="col-3">
        <div className="category-wrap">
          <div className="bg-shape bg-theme-yellow border-yellow"></div>
          <a href="shop.html"> <img className="category img-fluid" src="assets/images/catagoeris/7.png" alt="category" /> </a>
          <span className="title-color">Ready to Eat Food</span>
        </div>
      </div>

      <div className="col-3">
        <div className="category-wrap">
          <div className="bg-shape bg-theme-orange border-orange"></div>
          <a href="shop.html"> <img className="category img-fluid" src="assets/images/catagoeris/8.png" alt="category" /> </a>
          <span className="title-color">Dales & Pulses </span>
        </div>
      </div>
    </div>
  </section>



  <section className="offer-section pt-0">
    <div className="offer">
      <div className="top-content">
        <div>
          <h4 className="title-color">Troca Mosteiros por Prémios!</h4>
          <p className="content-color">Os melhores prémios ao teu alcance</p>
        </div>
        <a href="" className="font-theme">Ver Todos</a>
      </div>

      <div className="offer-wrap">

      {this.state.premios.map((item) => 
<>
<div className="product-list media">
          <a href=""><img src="assets/images/product/8.png" className="img-fluid" alt="offer" /></a>
          <div className="media-body">
         


            <a href="" className="font-sm">  {item.nome}  </a>
            <span className="content-color font-xs"></span>
            <span className="content-color font-xs">{item.valor} Mosteiros</span>
          </div>

        </div>
</>
    ) }

       

    

      </div>
    </div>
  </section>


  </main>
  <Footer />
  </>

);
    }
}

export default Home;



function Header() {
  return (
    <>
    <div className="skeleton-loader">
    
    <header className="header">
      <div className="logo-wrap">
        <i className="iconly-Category icli"></i>
        <a href="index.html"> <img className="logo logo-w" src="assets/images/logo/logo-w.png" alt="logo" /></a
        ><a href="index.html"> <img className="logo" src="assets/images/logo/logo.png" alt="logo" /></a>
      </div>
      <div className="avatar-wrap">
        <span className="font-sm"><i className="iconly-Location icli font-xl"></i> Los Angeles</span>
        <a href="account.html"> <img className="avatar" src="assets/images/avatar/avatar.jpg" alt="avatar" /></a>
      </div>
    </header>
    
    <div className="main-wrap index-page mb-xxl">
  
    
     
      <div className="banner-section section-p-t ratio2_1">
        <div className="h-banner-slider">
          <div>
            <div className="banner-box">
              <div className="bg-img"></div>
            </div>
          </div>
          <div>
            <div className="banner-box">
              <div className="bg-img"></div>
            </div>
          </div>
        </div>
      </div>
   
      <div className="recently section-p-t">
        <div className="recently-wrap">
          <h3 className="font-md sk-hed"></h3>
          <img className="corner" src="assets/svg/corner-sk.svg" alt="corner" />
          <ul className="recently-list">
            <li className="item">
              <div className="img"></div>
            </li>
            <li className="item"><div className="img"></div></li>
            <li className="item"><div className="img"></div></li>
            <li className="item"><div className="img"></div></li>
            <li className="item"><div className="img"></div></li>
            <li className="item"><div className="img"></div></li>
          </ul>
        </div>
      </div>
     
      <div className="category section-p-t">
        <h3 className="font-sm"><span></span><span className="line"></span></h3>
        <div className="row gy-sm-4 gy-2">
          <div className="col-3">
            <div className="category-wrap">
              <div className="bg-shape"></div>
              <span className="font-xs title-color"></span>
            </div>
          </div>
          <div className="col-3">
            <div className="category-wrap">
              <div className="bg-shape"></div>
              <span className="font-xs title-color"></span>
            </div>
          </div>
          <div className="col-3">
            <div className="category-wrap">
              <div className="bg-shape"></div>
              <span className="font-xs title-color"></span>
            </div>
          </div>
          <div className="col-3">
            <div className="category-wrap">
              <div className="bg-shape"></div>
              <span className="font-xs title-color"></span>
            </div>
          </div>
          <div className="col-3">
            <div className="category-wrap">
              <div className="bg-shape"></div>
              <span className="font-xs title-color"></span>
            </div>
          </div>
          <div className="col-3">
            <div className="category-wrap">
              <div className="bg-shape"></div>
              <span className="font-xs title-color"></span>
            </div>
          </div>
          <div className="col-3">
            <div className="category-wrap">
              <div className="bg-shape"></div>
              <span className="font-xs title-color"></span>
            </div>
          </div>
          <div className="col-3">
            <div className="category-wrap">
              <div className="bg-shape"></div>
              <span className="font-xs title-color"> </span>
            </div>
          </div>
        </div>
      </div>
   
      <div className="offer-section section-p-t">
        <div className="offer">
          <div className="top-content">
            <div>
              <h4 className="title-color">Say hello to Offers!</h4>
              <p className="content-color">Best price ever of all the time</p>
            </div>
            <a href="javascript(0)" className="font-xs font-theme">See all</a>
          </div>

          <div className="offer-wrap">
            <div className="product-list media">
              <a href="javascript(0)"><img src="assets/images/product/8.png" alt="offer" /></a>
              <div className="media-body">
                <a href="javascript(0)" className="font-sm"> Assorted Capsicum Combo </a>
                <span className="content-color font-xs">500g</span>
                <span className="title-color font-sm">$25.00 <span className="badges-round bg-theme-theme font-xs">50% off</span></span>
                <div className="plus-minus d-xs-none">
                  <i className="sub" data-feather="minus"></i>
                 
                  <i className="add" data-feather="plus"></i>
                </div>
              </div>
            </div>

            <div className="product-list media">
              <a href="javascript(0)"><img src="assets/images/product/6.png" alt="offer" /></a>
              <div className="media-body">
                <a href="javascript(0)" className="font-sm"> Assorted Capsicum Combo </a>
                <span className="content-color font-xs">500g</span>
                <span className="title-color font-sm">$25.00 <span className="badges-round bg-theme-theme font-xs">50% off</span></span>
                <div className="plus-minus d-xs-none">
                  <i className="sub" data-feather="minus"></i>
           
                  <i className="add" data-feather="plus"></i>
                </div>
              </div>
            </div>

            <div className="product-list media">
              <a href="javascript(0)"><img src="assets/images/product/7.png" alt="offer" /></a>
              <div className="media-body">
                <a href="javascript(0)" className="font-sm"> Assorted Capsicum Combo </a>
                <span className="content-color font-xs">500g</span>
                <span className="title-color font-sm">$25.00 <span className="badges-round bg-theme-theme font-xs">50% off</span></span>
                <div className="plus-minus d-xs-none">
                  <i className="sub" data-feather="minus"></i>
                  
                  <i className="add" data-feather="plus"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
 
    </div>

  </div>





<header className="header">
    <div className="logo-wrap">
      <i className="iconly-Category icli nav-bar"></i>
      <a href="index.html"> <img className="logo logo-w" src="assets/images/logo/logo-w.png" alt="logo" /></a><a href="index.html"> <img className="logo" src="assets/images/logo/logo.png" alt="logo" /></a>
    </div>
    <div className="avatar-wrap">
      <span className="font-sm"><i className="iconly-Location icli font-xl"></i> Los Angeles</span>
      <a href="account.html"> <img className="avatar" src="assets/images/avatar/avatar.jpg" alt="avatar" /></a>
    </div>
  </header>


    <a href="javascript:void(0)" class="overlay-sidebar"></a>
    <aside class="header-sidebar">
      <div class="wrap">
        <div class="user-panel">
          <div class="media">
            <a href="account.html"> <img src="assets/images/avatar/avatar.jpg" alt="avatar" /></a>
            <div class="media-body">
              <a href="account.html" class="title-color font-sm"
                >Andrea Joanne
                <span class="content-color font-xs">andreajoanne@gmail.com</span>
              </a>
            </div>
          </div>
        </div>

       
        <nav class="navigation">
          <ul>
            <li>
              <a href="index.html" class="nav-link title-color font-sm">
                <i class="iconly-Home icli"></i>
                <span>Home</span>
              </a>
              <a class="arrow" href="index.html"><i data-feather="chevron-right"></i></a>
            </li>

            <li>
              <a href="pages-list.html" class="nav-link title-color font-sm">
                <i class="iconly-Paper icli"></i>
                <span>Fastkart Pages list</span>
              </a>
              <a class="arrow" href="pages-list.html"><i data-feather="chevron-right"></i></a>
            </li>

            <li>
              <a href="category-wide.html" class="nav-link title-color font-sm">
                <i class="iconly-Category icli"></i>
                <span>Shop by Category</span>
              </a>
              <a class="arrow" href="category-wide.html"><i data-feather="chevron-right"></i></a>
            </li>

            <li>
              <a href="order-history.html" class="nav-link title-color font-sm">
                <i class="iconly-Document icli"></i>
                <span>Orders</span>
              </a>
              <a class="arrow" href="order-history.html"><i data-feather="chevron-right"></i></a>
            </li>

            <li>
              <a href="wishlist.html" class="nav-link title-color font-sm">
                <i class="iconly-Heart icli"></i>
                <span>Your Wishlist</span>
              </a>
              <a class="arrow" href="wishlist.html"><i data-feather="chevron-right"></i></a>
            </li>

            <li>
              <a href="javascript:void(0)" data-bs-toggle="offcanvas" data-bs-target="#language" aria-controls="language" class="nav-link title-color font-sm">
                <img src="assets/icons/png/flags.png" alt="flag" />
                <span>Langauge</span>
              </a>
              <a class="arrow" href="javascript:void(0)"><i data-feather="chevron-right"></i></a>
            </li>

            <li>
              <a href="account.html" class="nav-link title-color font-sm">
                <i class="iconly-Add-User icli"></i>
                <span>Your Account</span>
              </a>
              <a class="arrow" href="account.html"><i data-feather="chevron-right"></i></a>
            </li>

            <li>
              <a href="notification.html" class="nav-link title-color font-sm">
                <i class="iconly-Notification icli"></i>
                <span>Notification</span>
              </a>
              <a class="arrow" href="notification.html"><i data-feather="chevron-right"></i></a>
            </li>

            <li>
              <a href="setting.html" class="nav-link title-color font-sm">
                <i class="iconly-Setting icli"></i>
                <span>Settings</span>
              </a>
              <a class="arrow" href="setting.html"><i data-feather="chevron-right"></i></a>
            </li>

            <li>
              <a href="javascript:void(0)" class="nav-link title-color font-sm">
                <i class="iconly-Graph icli"></i>
                <span>Dark</span>
              </a>

              <div class="dark-switch">
                <input id="darkButton" type="checkbox" />
                <span></span>
              </div>
            </li>

            <li>
              <a href="javascript:void(0)" class="nav-link title-color font-sm">
                <i class="iconly-Filter icli"></i>
                <span>RTL</span>
              </a>

              <div class="dark-switch">
                <input id="rtlButton" type="checkbox" />
                <span class="before-none"></span>
              </div>
            </li>
          </ul>
        </nav>
       
      </div>

      <div class="contact-us">
        <span class="title-color">Contact Support</span>
        <p class="content-color font-xs">If you have any problem,queries or questions feel free to reach out</p>
        <a href="javascript:void(0)" class="btn-solid"> Contact Us </a>
      </div>
    </aside>
    
  
 
  </>

  );
}

export default Header;
import { BehaviorSubject } from 'rxjs';
import { handleResponse } from '../_helpers';
import React from 'react';
const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

const authenticationService = {
    login,
    logout,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue () { return currentUserSubject.value }
};

async function login(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    };

    return fetch(localStorage.getItem('api_url_grupoff')+'/api/utilizador/authenticate', requestOptions)
        .then(handleResponse)
        .then(user => {      
            console.log(user)      
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user));
         
            currentUserSubject.next(user);
    
            return user;
        }).catch(e => {
            localStorage.removeItem('currentUser');
            currentUserSubject.next(null);
            return e;
        });
}

function logout() {
    // remove user from local storage to log user out
  
    localStorage.removeItem('currentUser');
    currentUserSubject.next(null);
    window.location.href = '/#/login';
}

export default authenticationService;


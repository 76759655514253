import React, { Component } from 'react';
import Header from '../paginas/header';
import Footer from '../paginas/footer';
import Protecao from '../paginas/protecao';


class IndicadorDetalhe extends Component {

    constructor(props) {
        super(props);
        this.state = { premios: [{nome: 'Bilhete', valor: '10'},{nome: 'Garrafa Vinho', valor: '20'},{nome: 'Desconto SPA', valor: '30yarn'}] }
    
      }

    componentDidMount() {
console.log(this.props.match.params.id)
        
      }

      render() {
    return (
      <>

<Header />


<main class="main-wrap product-page mb-xxl">

      <div class="banner-box product-banner">
        <div class="banner">
          <img src="assets/images/banner/veg.png" alt="veg" />
        </div>
        <div class="banner">
          <img src="assets/images/banner/veg.png" alt="veg" />
        </div>
        <div class="banner">
          <img src="assets/images/banner/veg.png" alt="veg" />
        </div>
        <div class="banner">
          <img src="assets/images/banner/veg.png" alt="veg" />
        </div>
      </div>

      <section class="product-section">
        <h1 class="font-md">Assorted Corn Combo- (include 3 pices)</h1>
        <div class="rating">
          <i data-feather="star"></i>
          <i data-feather="star"></i>
          <i data-feather="star"></i>
          <i data-feather="star"></i>
          <i data-feather="star"></i>
          <span class="font-xs content-color">(150 Ratings)</span>
        </div>
        <div class="price"><span>$25.00</span><del>$45.00</del><span>25% off</span></div>


        <div class="select-group">

          <div class="size-Select" data-bs-toggle="offcanvas" data-bs-target="#quantity">
            <div class="size-box">
              <span class="font-sm title-color">500 g / $24.00</span>
            </div>
            <i data-feather="chevron-right"></i>
          </div>
          
          <div class="size-Select" data-bs-toggle="offcanvas" data-bs-target="#time" aria-controls="time">
            <div class="size-box">
              <span class="font-sm title-color">Delivery Time</span>
            </div>
            <i data-feather="chevron-right"></i>
          </div>
 
        </div>
      
        <div class="product-detail section-p-t">
          <div class="product-detail-box">
            <h2 class="title-color">Product Details</h2>
            <p class="content-color font-base">Refillable, Reusable, High Quality Glass Roll on Bottles with Silver cap For Essential Oil Blends DIY Perfume Cosmetics Beauty Products Ideal</p>
          </div>

    
          <div class="accordion" id="accordionExample">

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">Manufacturer Details</button>
              </h2>
              <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p class="content-color font-base">
                    Corn Combo is a tricky thing to balance in product descriptions. Too much humor and you’ll come across as abrasive; too little humor and it won’t have the effect that you want. Learn from those that do humor right like Dollar Shave Club.
                  </p>
                </div>
              </div>
            </div>
   
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  Product Disclaimer
                </button>
              </h2>
              <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p class="content-color font-base">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla ullam iste aliquam, commodi voluptates doloremque. Autem exercitationem vel eaque in odit expedita non blanditiis,
                    perspiciatis maiores cum tempora quo distinctio?
                  </p>
                </div>
              </div>
            </div>
       
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                  Features & details
                </button>
              </h2>
              <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p class="content-color font-base">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla ullam iste aliquam, commodi voluptates doloremque. Autem exercitationem vel eaque in odit expedita non blanditiis,
                    perspiciatis maiores cum tempora quo distinctio?
                  </p>
                </div>
              </div>
            </div>
       
          </div>
  
        </div>

      </section>


  </main>
  <Footer />
  </>

);
    }
}

export default IndicadorDetalhe;